<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Claim Komisi" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-!2/12 w-full">
            <label class="ml-1 text-xs">Tgl Claim *</label>
            <flat-pickr class="w-full" v-model="data.tgl_diajukan"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Total Claim</label>
            <vs-input class="w-full" :value="totalClaim | idr" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" />
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Memproses...</span>
              <span v-if="!isLoading">Claim</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import KomisiRepository from '@/repositories/marketing/komisi-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'Claim',
  props: {
    isActive: { required: true, type: Boolean },
    listCairItems: { required: true, type: Array }
  },
  components: {
    ValidationErrors,
    flatPickr
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.keterangan = 'Pengajuan pencairan komisi'
      }
    }
  },
  computed: {
    totalClaim () {
      return _.sumBy(this.listCairItems, item => parseFloat(item.total))
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        tgl_diajukan: moment().format('YYYY-MM-DD'),
        keterangan: null
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      KomisiRepository.claim(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        tgl_diajukan: this.data.tgl_diajukan,
        keterangan: this.data.keterangan,
        idsCair: _.map(this.listCairItems, item => item.id)
      }
    },

    onSuccess () {
      this.notifySuccess('Berhasil diajukan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
